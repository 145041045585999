<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.menuClose();
      }
    },
  },
  methods: {
    changeLocale(langue) {
      this.$i18n.locale = langue;
    },
    menuOpen(e) {
      this.checked = e.target.checked;
    },
    menuClose() {
      this.checked = false;
      if (this.$refs.active) {
        this.$refs.active.checked = false;
      }
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === "fr" ? "en" : "fr";
    },
    loadView(route) {
      this.checked = false;
      this.$refs.active.checked = false;
      setTimeout(() => {
        this.$router.push(route);
      }, 200);
    },
    changeTheme() {
      const bodyElement = document.body;
      if (localStorage.getItem("light-theme")) {
        localStorage.removeItem('light-theme');
        bodyElement.classList.remove("light-theme");
      } else {
        localStorage.setItem('light-theme',true);
        bodyElement.classList.toggle("light-theme");
      }
    },
    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/signin");
    },
  },
};
</script>

<template>
  <div class="main-menu">
    <div class="menu">
      <input type="checkbox" id="active" ref="active" @change="menuOpen" />
      <label for="active" class="menu-btn">
        <div class="box">
          <div class="btn" :class="checked ? 'active' : 'not-active'">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </label>
      <div class="wrapper" @click="menuClose">
        <div class="wrapper-content">
          <div class="wrapper-links">
            <div class="mobile-nav-link">
              <router-link class="router-style" to="/argos">{{
                $t("main-menu.reco")
              }}</router-link>
              <div>
                <img class="icon-color" src="../../assets/icons/chevron-right.svg" alt="" />
              </div>
            </div>
            <div class="mobile-nav-link">
              <router-link class="router-style" to="/ulys">{{
                $t("main-menu.recup")
              }}</router-link>
              <div>
                <img class="icon-color" src="../../assets/icons/chevron-right.svg" alt="" />
              </div>
            </div>
            <div class="mobile-nav-link">
              <router-link class="router-style" to="/event">{{
                $t("main-menu.event")
              }}</router-link>
              <div>
                <img class="icon-color" src="../../assets/icons/chevron-right.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="wrapper-options">
            <div class="wrapper-option">
              <div class="mobile-option-link" @click="changeTheme">
                <div>{{ $t("main-menu.theme") }}</div>
                <div>
                  <img
                    class="margin-icon icon-invert"
                    src="../../assets/icons/theme.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="wrapper-option">
            <div class="mobile-option-link" @click="logout">
              <div>{{ $t("main-menu.signout") }}</div>
              <div>
                <img
                  class="margin-icon icon-invert"
                  src="../../assets/icons/logout.svg"
                  alt=""
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.margin-icon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
.router-style {
  text-decoration: none;
  color: var(--white);
}
.mobile-nav-link {
  display: flex;
  width: 80%;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 1rem;
  transition: color 0.2s ease;
  margin-bottom: 2rem;
}
.mobile-nav-link:hover {
  color: var(--white);
}
.mobile-nav-link .router-link-exact-active {
  color: var(--blue-dark);
  font-family: "LatoBold", sans-serif;
}
.main-menu {
  position: fixed;
  display: block;
  height: 60px;
  width: 50vw;
  margin-left: 50vw;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  width: 55px;
}

.wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background: var(--grey-dark);
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  z-index: 3;
}

.wrapper-content {
  width: 100%;
  height: 50%;
  padding: 150px 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.wrapper-links {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}
.wrapper-options {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}
.wrapper-option {
  display: flex;
  flex-direction: row;
}
.mobile-option-link {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: color 0.2s ease;
  margin-bottom: 2rem;
}
.menu-icon {
  height: 34px;
  width: 30px;
  color: var(--blue-dark);
  transition: color 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}
.menu-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.menu-icon:hover {
  cursor: pointer;
  color: var(--white);
  transform: scale(1.1);
}

input[type="checkbox"] {
  display: none;
}

#active:checked ~ .wrapper {
  left: 0;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  width: 30px;
  cursor: pointer;
}

.no-deco {
  text-decoration: none;
}

span {
  display: block;
  width: 100%;
  height: 2px;
  background: var(--grey-light);
  transition: all 0.3s;
  position: relative;
}

.link-mobile {
  color: var(--white);
  padding: 5px 10px;
  text-align: left;
}

.link-box {
  width: calc(100% - 20px);
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid var(--blue-darker);
}

.active-link {
  background-color: var(--grey);
}

.active-link .link-mobile {
  color: var(--white);
}
.active-link .icon {
  color: var(--blue);
}

.link-box {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}
.link-box:hover {
  transform: scale(1.05);
}
.icon {
  width: 20px;
  height: 20px;
  color: var(--blue-darker);
}

span + span {
  margin-top: 7px;
}

.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 9px;
    transform: rotate(0);
  }
  100% {
    top: 9px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 9px;
    transform: rotate(45deg);
  }
  50% {
    top: 9px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 9px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 9px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
